/* ==================
	10.4 Error 404
=================== */
.error-404{
	.inner-content{
		text-align: center;
		max-width: 550px;
		margin: auto;
	}
	.error-title{
		font-size: 180px;
		line-height: 180px;
		margin-bottom: 15px;
		
		span {
			width: 145px;
			height: 145px;
			display: inline-block;
			background-image: url(../../images/icons/error.gif);
			background-size: 100%;
			margin: 0 10px;
		}
	}
	.error-text{
		margin-bottom: 15px;
	}
	p{
		margin-bottom: 30px;
	}
	
	@include respond('phone'){
		.error-title{
			font-size: 120px;
			line-height: 120px;
			
			span {
				width: 100px;
				height: 100px;
			}
		}
	}
}